<div class="navbar-dark elegant-color p-5">
  <div class="container">
    <div class="text-center text-white">
      <h2>{{title}}</h2>
      <p>{{message}}</p>
    </div>
    <div class="row">
      <div *ngFor="let team of teams" [class]="class">
        <div class="p-4 m-1">
          <app-card (cardButtonClicked)="cardClicked(team.buttonRoute)" [src]="team.src" [cardTitle]="team.cardTitle"
            [cardText]="team.cardText" [buttonText]="team.buttonText" [paddingCSSclass]="'p-5'" [delay]="GetDelay(team.id)">
          </app-card>
        </div>
      </div>
    </div>
  </div>
</div>
