
<div class="carousel" (mouseleave)="showControls = false">
  
  <div *ngIf="showControls && multipleImages" class="controls" [@fadeInOut]>
    <div class="next" (click)="next()"><i class="fas fa-chevron-circle-right"></i></div>
    <div class="previous" (click)="previous()"><i class="fas fa-chevron-circle-left"></i></div>
    <div *ngIf="images.length > 1" class="thumbnails">
      <span class="thumbnail" *ngFor="let image of images; let i = index">
        <i (click)="setIndex(i)" class="fas fa-circle"></i>
      </span>
    </div>
  </div>

  <div class="slide" (mouseenter)="showControls = true">
    <img (click)="SelectDocument(currentIndex)" class="img-fluid image" [src]="s3bucket + images[currentIndex].location" [@fadeImage]="imageState">
  </div>

</div>