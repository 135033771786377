import { Injectable } from '@angular/core';
import { IGallery } from '../models/IGallery';
import { HttpClient } from '@angular/common/http';
import gallery from '../../data/galleryItems.json';


@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private http: HttpClient) { }

  GetGalleryItemsALL() {
    return gallery;
  }

  GetGalleryIntu() {
    return gallery.filter(w => w.isIntu);
  }


  GetGalleryPerfectFit() {
    return gallery.filter(w => w.isPerfectFit);
  }

  GetGallery(categories: string[], types: string[], onlyPerfectFit: boolean, onlyIntu: boolean, ) {

    // tslint:disable-next-line: max-line-length
    const categorised = categories === undefined || categories.length === 0  ? gallery : gallery.filter(w => categories.some(s => w.category.includes(s)));
    // tslint:disable-next-line: max-line-length
    const typed = types === undefined || types.length === 0 ? categorised : categorised.filter(w => types.some(f => w.type.includes(f)));

    const intud = onlyIntu === undefined ? typed : typed.filter(w => !onlyIntu || w.isIntu === onlyIntu);

    const perf = onlyPerfectFit === undefined ? intud : intud.filter(w => !onlyPerfectFit || w.isPerfectFit === onlyPerfectFit);

    return perf;
  }
}
