<div class="m-3">
  <div class="row">
    <div class="col">
      <div class="vogue-header text-center">
        <h2>Louvolite - Vogue System</h2>
        <p class="pb-3">
          We can curve and arch this beautifully elegant system in all of these
          stylish colours to match or compliment any decor
        </p>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-around text-center">
    <div class="col my-auto mx-auto">
      <h3>Ice White</h3>
      <img class="img-fluid" src="./assets/images/LL_Slimeline_Vogue_Headrail_Ice_White.jpg" />
    </div>
    <div class="col my-auto mx-auto">
      <h3>Champagne</h3>

      <img class="img-fluid" src="./assets/images/LL_Slimeline_Vogue_Headrail_Champagne_Gold.jpg" />
    </div>

    <div class="col my-auto mx-auto">
      <h3>Black</h3>

      <img class="img-fluid" src="./assets/images/LL_Slimeline_Vogue_Headrail_Black.jpg" />

    </div>
    <div class="col my-auto mx-auto">
      <h3>Expresso Brown</h3>

      <img class="img-fluid" src="./assets/images/LL_Slimeline_Vogue_Headrail_Espresso_Brown.jpg" />

    </div>
    <div class="col my-auto mx-auto">
      <h3>Brushed Aluminium</h3>

      <img class="img-fluid" src="./assets/images/LL_Slimeline_Vogue_Headrail_Brushed_Aluminium.jpg" />

    </div>
    <div class="col my-auto mx-auto">
      <h3>Anthracite</h3>

      <img class="img-fluid" src="./assets/images/LL_Slimeline_Vogue_Headrail_Anthracite.jpg" />

    </div>
  </div>
</div>