<!-- <li *ngIf="isLoggedIn" class="nav-item" [matMenuTriggerFor]="useraccounts">
  <a class="nav-link waves-light" dropdown-toggle mdbWavesEffect>
    <i class="fas user text-white"></i>{{user.username}}<span class="caret text-white"></span>
  </a>
</li> -->

<div *ngIf="isLoggedIn" class="nav-item cursor" [matMenuTriggerFor]="useraccounts">
  <a class="nav-link waves-light c-blue" dropdown-toggle mdbWavesEffect>
    <i class="fas fa-user text-white"></i>&nbsp;{{user?.username}}<span class="caret text-white"></span>
  </a>
</div>

<mat-menu class="navbar-dark base-color" #useraccounts="matMenu">
  <div class="dropdown-item">
    <a [routerLink]="'account-home'"><i class="fas fa-home text-white"></i>&nbsp;&nbsp;Account Home</a>
  </div>
  <div class="dropdown-item">
    <a [routerLink]="'orders'"><i class="fas fa-list text-white"></i>&nbsp;&nbsp;View Orders</a>
  </div>
  <div class="dropdown-item">
    <a [routerLink]="'account'"><i class="fas fa-user text-white"></i>&nbsp;&nbsp;Manage Account</a>
  </div>
  <div class="dropdown-item">
    <a (click)="signOut()" href="#"><i class="fas fa-sign-out-alt text-white"></i>&nbsp;&nbsp;Sign Out</a>
  </div>
</mat-menu>


<a *ngIf="!isLoggedIn" [routerLink]="['/login']" class="nav-link waves-light c-blue" routerLinkActive="router-link-active">
  <i class="fas user text-white"></i>&nbsp;Retailers Login
</a>
