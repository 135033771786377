import { Component, OnInit, Input } from '@angular/core';
import { ITeam } from 'src/app/models/ITeam';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  class: string;

  teams: ITeam[] = [];

  @Input() title: string;
  @Input() message: string;

  constructor(private sanitizer: DomSanitizer, private router: Router, private dataservice: DataService) {
    dataservice.GetTeams().subscribe((data) => {
      this.teams = data;
      this.setClass(data.length);
    },
    (err) => {
      console.log(err);
    });
  }

  ngOnInit(): void {

  }

  cardClicked(cardRoute) {
    this.router.navigate(['/' + cardRoute]);
  }

  GetDelay(delay: number) {
    return delay * 0.2;
  }

  setClass(length: number) {
    this.class = 'col-sm-12 col-md-' + (12 / length);
  }

}
