import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Product, Tag } from 'src/app/services/API.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-product-picker',
  templateUrl: './product-picker.component.html',
  styleUrls: ['./product-picker.component.scss']
})
export class ProductPickerComponent implements OnInit {

  products: Product[] = [];
  productsFiltered: Product[] = [];

  tags: Tag[] = [];
  tagsPicked: Tag[] = [];

  systems: TagOption[] = [{tag:"*", display: "Any"},{tag:"curtain", display: "Curtain"},{tag:"vertical", display: "Vertical"}];
  systemSelected: TagOption;

  types: TagOption[] = [{tag:"*", display: "Any"},{tag:"track", display: "Track"},{tag:"extra", display: "Extra"}];
  typeSelected: TagOption;

  suppliers: TagOption[] = [{tag:"*", display: "Any"},{tag:"somfy", display: "Somfy"},{tag:"louvolite", display: "Louvolite"},{tag:"benthin", display: "Benthin"}];
  supplierSelected: TagOption;

  @Output() productSelected = new EventEmitter<Product>();
  @Output() trackSelected = new EventEmitter<string>();

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.products = this.authService.website.products.items.sort((a,b)=> a.name < b.name ? -1:1).sort((a,b)=> a.supplierID > b.supplierID ? -1:1).sort((a,b)=> a.tags.items.some(s=>s.tag.name === "track") > b.tags.items.some(s=>s.tag.name === "track") ? -1:1) as  Product[];
    this.authService.website.products.items.forEach(p => p.tags.items.forEach(t => {
      if (!this.tags.some(s => s.name === t.tag.name)) {
        this.tags.push(t.tag)
      }
    }));
    this.resetSelectors();
  }

  selectProduct(product: Product) {
    this.productSelected.emit(product);
  }

  selectTrack(trackType: string) {
    this.trackSelected.emit(trackType);
  }

  TagsPicked(Tags: Tag[]) {
    this.tagsPicked = Tags;
    this.filterProducts();
  }
  
  filterProducts() {
    if (this.tagsPicked.length === 0) {
      this.Reset();
     // return;
    }

    // show products that have all of the tags picked
   // this.productsFiltered = this.products.filter(p => this.tagsPicked.every(t => p.tags.items.some(s => s.tag.name === t.name)));
    // this.productsFiltered = this.products.filter(p => p.tags.items.some(t => this.tagsPicked.some(s => s.name === t.tag.name)));

    if(this.systemSelected.tag !== "*") {
      this.productsFiltered = this.productsFiltered.filter(p => p.tags.items.some(t => t.tag.name === this.systemSelected.tag));
    }
    if(this.typeSelected.tag !== "*") {
      this.productsFiltered = this.productsFiltered.filter(p => p.tags.items.some(t => t.tag.name === this.typeSelected.tag));
    }
    if(this.supplierSelected.tag !== "*") {
      this.productsFiltered = this.productsFiltered.filter(p => p.tags.items.some(t => t.tag.name === this.supplierSelected.tag));
    }

  }

  Reset() {
    this.productsFiltered = this.products;
  }

  resetSelectors() {
    this.systemSelected = this.systems[0];;
    this.typeSelected = this.types[0];
    this.supplierSelected = this.suppliers[0];
    this.Reset();
  }
}

export interface TagOption {
  display: string;
  tag: string;
}