import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cws-logo',
  templateUrl: './cws-logo.component.html',
  styleUrls: ['./cws-logo.component.scss']
})

export class CwsLogoComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

}
