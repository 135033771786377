import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splay-bay',
  templateUrl: './splay-bay.component.html',
  styleUrls: ['./splay-bay.component.scss']
})
export class SplayBayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
