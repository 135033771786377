<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550.78 196.47">
    <defs>
      <style>
        .cls-1 {
          fill: #fdff00;
          stroke: #2e3192;
        }
  
        .cls-1, .cls-2, .cls-3, .cls-4 {
          stroke-miterlimit: 10;
        }
  
        .cls-5 {
          letter-spacing: 0em;
        }
  
        .cls-6 {
          font-family: MyriadPro-Regular, 'Myriad Pro';
          font-size: 12px;
        }
  
        .cls-7, .cls-8 {
          stroke-width: 0px;
        }
  
        .cls-2 {
          stroke: #ff371f;
        }
  
        .cls-2, .cls-3, .cls-4 {
          fill: none;
        }
  
        .cls-9 {
          letter-spacing: 0em;
        }
  
        .cls-3 {
          stroke: #fff;
          stroke-width: 10px;
        }
  
        .cls-4 {
          stroke-dasharray: 0 0 8 2;
          stroke-width: 2px;
        }
  
        .cls-8 {
          fill: #8b2d0f;
        }
  
        .cls-10 {
          letter-spacing: .01em;
        }
      </style>
    </defs>
    <g id="Reveal2">
      <rect class="cls-8" x=".5" y="15.19" width="53" height="62.17"/>
      <path class="cls-7" d="M53,15.69v61.17H1V15.69h52M54,14.69H0v63.17h54V14.69h0Z"/>
    </g>
    <g id="Reveal1">
      <rect class="cls-8" x="477.28" y="133.05" width="53" height="62.92"/>
      <path class="cls-7" d="M529.77,133.55v61.92h-52v-61.92h52M530.77,132.55h-54v63.92h54v-63.92h0Z"/>
    </g>
    <polygon class="cls-1" points="517.21 132.55 523.98 132.55 523.98 18.34 54.06 18.34 54.06 26.34 516.5 26.34 517.21 132.55"/>
    <path id="curveNoReveal" class="cls-3" d="M491.31,127.79v-57.58c0-10.41-10.91-21.64-22.91-21.64l-410.05,1.8"/>
    <g id="measureBC">
      <g>
        <line class="cls-4 dimBCs" x1="505.36" y1="47.37" x2="505.64" y2="125.38"/>
        <path class="dimBC" d="M495.81,61.07c.47.3,1.08.15,1.38-.31l8.17-12.93,8.27,12.87c.3.47.92.6,1.38.3.46-.3.6-.92.3-1.38l-9.11-14.2c-.18-.29-.5-.46-.85-.46s-.66.18-.84.47l-9.01,14.26c-.1.17-.15.35-.15.54,0,.33.17.65.47.84Z"/>
        <path class="dimBC" d="M496,111.75c.46-.3,1.08-.16,1.38.3l8.27,12.87,8.17-12.93c.3-.47.91-.61,1.38-.31.47.3.6.92.31,1.38l-9.01,14.26c-.18.29-.5.46-.84.47s-.66-.17-.85-.46l-9.11-14.2c-.11-.17-.16-.35-.16-.54,0-.33.16-.65.46-.85Z"/>
      </g>
    </g>
    <g id="messureAB">
      <g>
          <line class="cls-4 dimABs" x1="494.11" y1="35.76" x2="60.76" y2="35.91"/>
          <path class="dimAB" d="M480.44,26.17c-.3.46-.16,1.08.31,1.38l12.91,8.21-12.9,8.22c-.47.3-.61.92-.31,1.38.3.46.92.6,1.38.31l14.23-9.07c.29-.18.46-.5.46-.84,0-.34-.18-.66-.46-.84l-14.23-9.06c-.17-.11-.35-.16-.54-.16-.33,0-.65.16-.85.46Z"/>
          <path class="dimAB" d="M74.43,26.3c.3.46.16,1.08-.31,1.38l-12.9,8.22,12.91,8.21c.47.3.61.92.31,1.38-.3.46-.92.6-1.38.31l-14.23-9.06c-.29-.18-.46-.5-.46-.84,0-.34.18-.66.46-.84l14.23-9.07c.17-.11.35-.16.54-.16.33,0,.65.16.85.46Z"/>
        </g>
    </g>
    <line id="guideBC" class="cls-2" x1="58.36" y1="11.43" x2="58.36" y2="64.39"/>
    <line id="GuideACNoReveal" class="cls-2" x1="540.05" y1="128.04" x2="464.29" y2="127.65"/>
    <text class="cls-6 dimAB" transform="translate(261.93 67.04)">A-B</text>
    <text class="cls-6 dimBC" transform="translate(456.88 90.09)">B-C</text>
    <line id="GuideACNoReveal-2" data-name="GuideACNoReveal" class="cls-2" x1="516.06" y1="44.14" x2="445.49" y2="43.77"/>
    <line id="guideBC-2" data-name="guideBC" class="cls-2" x1="496.52" y1="27.89" x2="496.52" y2="74.75"/>
    <text class="cls-6" transform="translate(543.82 130.79)"><tspan x="0" y="0">C</tspan></text>
    <text class="cls-6" transform="translate(502.5 39.31)"><tspan x="0" y="0">B</tspan></text>
    <text class="cls-6" transform="translate(54.78 10.04)"><tspan x="0" y="0">A</tspan></text>
  </svg>