import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { APIService, Customer, Website } from './API.service';
import { API_CWSService } from './API_CWS.service';
export interface AuthState {
  isLoggedIn: boolean;
  username: string | null;
  id: string | null;
  email: string | null;
  groups: string | null;
  websiteID: string | null;
  customerID: string | null;
}

const initialAuthState = {
  isLoggedIn: false,
  username: null,
  id: null,
  email: null,
  groups: null,
  websiteID: null,
  customerID: null
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly isRegistering = new BehaviorSubject<boolean>(false);
  readonly isRegistering$ = this.isRegistering.asObservable();

  public customer: Customer;
  public website: Website;
  
  private readonly authState = new BehaviorSubject<AuthState>(initialAuthState);
  /** AuthState as an Observable */
  readonly auth$ = this.authState.asObservable();
  /** Observe the isLoggedIn slice of the auth state */
  readonly isLoggedIn$ = this.auth$.pipe(map(state => state.isLoggedIn));

  constructor(private apiService: APIService, private cws: API_CWSService ) {
    // Get the user on creation of this service
    Auth.currentAuthenticatedUser().then(
      (user: any) => this.setUser(user),
      err => this.destroyUser()
    );

    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      switch(event) {
        case 'signIn':
          this.setUser(data);
          break;
        case 'tokenRefresh':
          break;
        case 'signOut':
          this.destroyUser();
          break;
          
        default:
          this.destroyUser();
          break;
      }
    });
  }

  private destroyUser() {
    this.authState.next(initialAuthState);
    this.customer = undefined;
  }

  private setUser(user: any) {
    if (!user) {
      this.destroyUser();
      return;
    }

    const authstate = {
      isLoggedIn: true,
      email: user.attributes.email,
      groups: this.GetGroups(user),
      username: user.username,
      id: user.attributes.sub,
      websiteID: user.attributes['custom:websiteID'],
      customerID: user.attributes['custom:customerID']
    } as AuthState;

    forkJoin([
      this.cws.GetBenditData(authstate.websiteID),
      this.apiService.GetCustomer(authstate.customerID)
    ]).subscribe(([website, customer]) => {
      this.website = website as Website;
      this.customer = customer as Customer;
      this.authState.next(authstate);
    }, (err) => {
      console.log(err);
      this.destroyUser();
    });
  }

  ChangeCustomer(customer: Customer) {
    this.customer = customer;
    this.authState.next({ ...this.authState.value, customerID: customer.id });
  }
  
  GetGroups(user: CognitoUserInterface) {
    return user.signInUserSession.accessToken.payload['cognito:groups'];
  }

  CurrentUserInGroup(group: string): Observable<boolean> {
    if(this.authState === undefined || this.authState.value.groups === null || this.authState.value.groups.length === 0){
      return  of(false);
    }
    return of(this.authState.value.groups.includes(group));
  }
}
