import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-icon-click',
  templateUrl: './banner-icon-click.component.html',
  styleUrls: ['./banner-icon-click.component.scss']
})
export class BannerIconClickComponent implements OnInit {

  @Input() title: string;
  @Input() discription: string;
  @Input() route: string;
  @Input() params: any;
  @Input() icon: string;
  @Input() buttonText: string = "Select";
  
  constructor() { }

  ngOnInit(): void {
  }

}
