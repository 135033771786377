<div class="container p-5">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6">
        <div>
          <img class="img-fluid" [src]="safeImgURL" alt="In house development of mobile apps and websites"
          title="In house development of mobile apps and websites">
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6">
      <mat-tab-group (selectedTabChange)="image()" (selectedIndexChange)="selectedIndex = $event">

        <mat-tab label = "SERVERLESS">
          <div class="p-4 h-100">
            <p>Our websites use the latest serverless technologies, no expensive servers to maintain meaning the best possible monthly costs, only pay for what you use.</p>
            <p>Expecting thousands of users for that new campaign? No problem, Amazon Web Services and Microsoft Azure serverless technologies automatically scales up to your needs.</p>
          </div>
        </mat-tab>

        <mat-tab label = "AWS">
          <div class="p-4 h-100">
            <p>As AWS (Amazon Web Services) specialists, we are using the industry leaders of cloud based serverless technologies.</p>
            <p>AWS also have world class storage, email and hosting services.</p>
            <p>Together with AWS we have the technology for whatever you throw at us.</p>
          </div>
        </mat-tab>

        <mat-tab label = "AZURE">
          <div class="p-4 h-100">
            <p>Azure is Microsofts could based services and is very similar to AWS but utilizes the full Microsft architecture.</p>
            <p>Cloud based services include database service such as SQL, application based services for websites and apps, all fully managed.</p>
            <p>Only pay for what you use, no expenseive servers to buy and maintain!</p>
          </div>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>
</div>

