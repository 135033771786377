import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-attribute-display',
  templateUrl: './attribute-display.component.html',
  styleUrls: ['./attribute-display.component.scss']
})
export class AttributeDisplayComponent implements OnInit {

  @Input() title: string;
  @Input() value: string;
  @Input() big: boolean = false;

  canShow: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if(this.value !== undefined && this.value !== null && this.value !== '')
    {
      this.canShow = true;
    }
  }
}
