import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-login-register",
  templateUrl: "./login-register.component.html",
  styleUrls: ["./login-register.component.scss"],
})
export class LoginRegisterComponent implements OnInit {
  isLoggedIn = false;
  hideSignUp = true;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.authService.isLoggedIn$.subscribe((data) => {
      if (data && !this.isLoggedIn) {
        this.isLoggedIn = data;
        this.router.navigate(["/account-home"]);
      }
    });
  }

  // StateChanged(authState: AuthState) {
  //   if (authState.isLoggedIn) {
  //     this.router.navigate(['/account']);
  //   }
  // }
}
