import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import { Amplify, Auth } from "aws-amplify";
import { AuthService } from "src/app/services/auth.service";
import aws_exports from "../../../../aws-exports";

@Component({
  selector: 'app-register-customer',
  templateUrl: './register-customer.component.html',
  styleUrls: ['./register-customer.component.scss']
})
export class RegisterCustomerComponent implements OnInit {

  hideSignUp = true;
  // isLoggedIn: boolean;
  customerID: string;
  websiteID: string;
  email: string;
  company: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authenticator: AuthenticatorService,
    public auth: AuthService
  ) {
    Amplify.configure(aws_exports);
  }

  ngOnInit(): void {
    this.auth.isRegistering.next(true);

    this.auth.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.auth.isRegistering.next(false);
        this.router.navigate(["/account-home"]);
      }
    });

    // Check route params if not 100% correct hide register form
    localStorage.setItem(
      "customerID",
      this.route.snapshot.paramMap.get("customerID")
    );
    localStorage.setItem(
      "websiteID",
      this.route.snapshot.paramMap.get("websiteID")
    );
    localStorage.setItem("email", this.route.snapshot.paramMap.get("email"));

    this.websiteID = this.route.snapshot.paramMap.get("websiteID");
    this.email = this.route.snapshot.paramMap.get("email");
    this.company = this.route.snapshot.paramMap.get("company");

    if (this.customerID && this.websiteID) {
      this.SetForm();
    } else {
      this.hideSignUp = true;
    }
  }

  SetForm() {
    this.hideSignUp = false;
  }

  services = {
    async handleSignUp(formData: Record<string, any>) {
      if (this.auth) {
        this.auth.isRegistering.next(false);
      }

      let { username, password, attributes } = formData;
      // custom username
      //username = username.toLowerCase();
      attributes.email = localStorage.getItem("email");
      attributes["custom:GROUP"] = "Customer";
      attributes["custom:websiteID"] = localStorage.getItem("websiteID");
      attributes["custom:customerID"] = localStorage.getItem("customerID");
      return Auth.signUp({
        username,
        password,
        attributes,
      });
    },
  };
}
