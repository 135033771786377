<div>
    <div class="row pb-1">
        <div class="col-2">
          <span><b>Order No.:</b><br />{{ order.orderNo }}</span>
        </div>
        <div class="col-2">
          <span><b>Reference:</b> <br />{{ order.customerReference }}</span>
        </div>
        <div class="col-2">
            <span><b>Tracks:</b><br />{{ order.orderItems.items?.length }}</span>
        </div>
        <div class="col-2">
          <span><b>Order Total:</b><br />{{ order.price | currency : "GBP" }}</span>
        </div>
        <div class="col-4">
          <span><b>Notes:</b><br />{{ order.orderNotes }}</span>
        </div>

</div>
<div class="m-3 alert alert-primary">
    <i class="fas fa-warn"></i>
    <span>Once ordered you will not be able to edit this order - would you like to proceed?</span>
</div>
<div class="d-flex justify-content-center">
    <div class="col-2">
        <span class="badge rounded-pill p-2 ps-4 pe-4 bg-primary">All prices + VAT</span>
    </div>
    <div class="col-2">
        <span class="badge rounded-pill p-2 ps-4 pe-4 bg-primary">Delivery £25 per consignment</span>
    </div>
</div>