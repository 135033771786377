import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { faUnderline } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header-user-menu',
  templateUrl: './header-user-menu.component.html',
  styleUrls: ['./header-user-menu.component.scss']
})
export class HeaderUserMenuComponent implements OnInit {

  isLoggedIn = false;
  user: User;

  constructor(private ngZone: NgZone, private router: Router, private authService: AuthService,
              public authenticator: AuthenticatorService) {
  }

  ngOnInit(): void {
    this.authService.auth$.subscribe(({ id, username, email }) => {
          this.ngZone.run(() => {
            this.user = { id, username, email } as User;
            this.isLoggedIn = this.user.id !== null;

            // if(this.isLoggedIn){
            //   this.router.navigate(["/account"])
            // } else {
            //   this.router.navigate(["/home"])
            // }
      });
    });
  }

  signOut() {
    this.authenticator.signOut();
  }
}
export interface User {
  id: string;
  username: string;
  email: string
}
