import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { bounceIn } from '../../../Animations/bounce';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
  animations: [
    trigger('anim', [
      transition('* => fadeIn', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 })),
      ]),
      transition('* => flipInX', [bounceIn])
    ])
  ]
})

export class ModalPopupComponent implements OnInit {

  @Input() title: string;
  @Input() descriptions: [];
  @Input() showPopup: boolean;
  @Input() btnCancelText: string;
  @Input() backgroundColourCSS = 'backColourWhite';
  @Input() animation = 'flipInX';
  @Input() btnActionText: string;

  btnShowAction: boolean;
  PanelCSS: string;
  btnShowCancel: boolean;

  @Output() cancelClicked = new EventEmitter();
  @Output() actionClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.btnShowCancel = this.btnCancelText !== undefined && this.btnCancelText.length > 0;
    this.btnShowAction = this.btnActionText !== undefined && this.btnActionText.length > 0;
    this.PanelCSS = this.backgroundColourCSS + ' ' + this.animation;
  }

  BtnActionClicked() {
    this.actionClicked.emit();
  }

  BtnCancelClicked() {
    this.cancelClicked.emit();
  }
}
