import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITeam } from 'src/app/models/ITeam';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  @Input()
  src: string;

  @Input()
  paddingCSSclass: string;

  @Input()
  cardTitle: string;

  @Input()
  cardText: string;

  @Input()
  buttonText: string;
  showButton: boolean;

  @Input() name: string;
  @Input() position: string;

  @Output() cardButtonClicked = new EventEmitter<string>();

  @Input()
  delay: number;

  constructor() {
   }

  ngOnInit(): void {
    this.showButton = this.buttonText !== undefined && this.buttonText.length > 0;
  }

  onCardButtonClicked() {
    this.cardButtonClicked.emit();
  }
}
