<div class="container-fluid">
  <app-banner-content [theme]="'dark'">
    <div class="container">
      <div class="text-center">
        <h1 class="lead">Somfy Motorised Curtain Tracks</h1>
        <p class="lead">
          Bend It Ltd have been a manufacturer of Somfy curtain tracks for
          several years and are now able to supply you directly
        </p>
        <p class="lead">
          Please contact us
          <a [routerLink]="'/curtain-tracks'" [fragment]="'ContactForm'">here</a> to request your supplier account
        </p>
      </div>
    </div>
  </app-banner-content>
</div>
<div class="container-fluid py-3">
  <div class="container">
    <div class="text-center row justify-content-center">
      <div class="col-md-4 my-auto">
        <img
          class="img-fluid py-3"
          src="/assets/images/somfy-logo.jpg"
          alt="somfy logo"
        />
      </div>
      <p class="lead">
        Somfy specialise in motorised curtain tracks which can be controlled by
        a touch of a button from your smart phone taking your home to the height
        of modern smart home technology.
      </p>
    </div>
  </div>
</div>

<div class="container">
  <div class="row align-items-center px-5">
    <div class="col-md-6">
      <img
        class="img-fluid w-100 py-5"
        src="./assets/images/somfy-room.jpg"
        alt="somfy living room"
      />
    </div>

    <div class="col-md-6">
      <div class="p-2 p-md-5">
        <h2 class="lead">Smart</h2>
        <p class="lead">
          Somfy electric curtain tracks can be controlled by your smart phone,
          smart control or even by voice activation allowing you to set the
          ambience in your home with ease
        </p>
      </div>
    </div>
  </div>
  <div class="row align-items-center px-5">
    <div class="col-md-6">
      <div class="p-2 p-md-5">
        <h2 class="lead">Easy</h2>
        <p class="lead">
          As manual use is not required for these tracks it makes them perfect
          for curtains in hard to reach places such as rooms with high ceilings
          or even for heavier curtains.
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <img
        class="img-fluid w-100 py-5"
        src="./assets/images/curtain-track.jpg"
        alt="somfy curtains"
      />
    </div>
  </div>
</div>

<div class="container pb-3">
  <app-feature-cards
    [title]="'Somfy control options'"
    [message]="
      'There are a number of ways to control your smart electric curtain tracks from using an app on your phone to voice control'
    "
    [features]="featuresTypes"
  ></app-feature-cards>
</div>

<div class="container pb-3">
  <app-contact-form
    id="ContactForm"
    [productType]="'tracks'"
  ></app-contact-form>
</div>
