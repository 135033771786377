<div class="container py-4">
  <div class="row" *ngIf="!adding">
    <div class="col">
      <h2 class="text-center">{{customer?.companyName}} Orders</h2>
      <app-order-table (editClicked)="editClicked($event)" (viewDetails)="ViewCurves($event)"
        (deleteClicked)="deleteClicked($event)" [orders]="orders" (SubmitOrder)="submitOrder($event)"
        (newOrderClicked)="AddNewOrder()" (refreshData)="refreshData()"></app-order-table>
    </div>
  </div>
  
  <app-order-form *ngIf="adding" [customerID]="userState.customerID" [order]="orderSelected"
    [viewingCurves]="viewDetails" (CancelClicked)="cancelClicked()" (OrderAdded)="addOrder($event)"
    (SubmitOrder)="submitOrder($event)" (OrderUpdated)="orderUpdated($event)">
  </app-order-form>
</div>
<app-loading [loading]="loading"></app-loading>