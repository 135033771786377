<div class="container-fluid mt-5">
  <div class="row p-3">
    <div class="col text-center">
      <h2 class="text-center">Bend It Retailers</h2>
      <p class="py-2">Use this map to find your local track suppliers.</p>
    </div>
  </div>
  <div class="row pb-3">
    <iframe
      src="https://www.google.com/maps/d/embed?mid=1p6Trk_As8kNDuKCR31qgcQXwqxXW7H1B&ehbc=2E312F&z=7"
      width="100%"
      height="600px"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</div>
