import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITeam } from '../models/ITeam';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { IContact } from '../models/IContact';
import { IFeature } from '../models/IFeature';
import { OpeningTime } from '../models/OpeningTime';
import { FAQ } from '../models/faq';
import { QVC } from '../models/qvc';
import { price } from '../models/priciing';
import { Retailer} from '../models/retailers';
import  { RestAPI  } from "@aws-amplify/api-rest";
@Injectable({
  providedIn: 'root'
})


export class DataService {

  GetRetailers(): Observable<Retailer[]> {
    return this.http.get<Retailer[]>('data/retailers.json');
  }

  GetOpeningTimes() {
    return this.http.get<OpeningTime[]>('data/openingTimes.json');
  }

  GetQvcItem(): Observable<QVC[]> {
    return this.http.get<QVC[]>('data/qvc.json');
  }

  GetPricing(): Observable<price[]> {
    return this.http.get<price[]>('data/pricing.json');
  }

  constructor(private http: HttpClient) { }

  GetTeams(): Observable<ITeam[]> {
    return this.http.get<ITeam[]>('data/team.json');
  }

  GetFAQs(): Observable<FAQ[]> {
    return this.http.get<FAQ[]>('data/faq.json');
  }

  GetFeatures(): Observable<IFeature[]> {
    return this.http.get<IFeature[]>('data/feature.json');
  }

  SendContactForm(contact: IContact) {
    const data = JSON.stringify(contact);
    return RestAPI.post('SesTemplateEmailer', '/email', { body: data });
//    return this.http.post<string>('https://57tcuymfij.execute-api.eu-west-1.amazonaws.com//mailFwd', data );
  }
}
