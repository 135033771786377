<div class="picker-container">
  <div class="row">
    <div class="col">
      <span class="tag-title">Tags
        <span class="picked" *ngIf="tagsPicked.length > 0"> - includes
          <span *ngFor="let picked of tagsPicked"> - {{picked.name}}
          </span>
        </span>
      </span>
    </div>
    <div class="col">
      <div class="float-right">
        <button class="btn btn-sm btn-outline-primary" (click)="clearTags()">Clear</button>
      </div>
    </div>
  </div>
  <div>
    <ul class="nav nav-fill justify-content-center">
      <li class="nav-item c-blue p-3 text-uppercase" *ngFor="let tag of tags">
        <app-tag-link [tag]="tag" [isActive]="isActive(tag)" (tagPicked)="tagClicked(tag)"></app-tag-link>
      </li>
    </ul>
  </div>
</div>