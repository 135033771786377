<app-header *ngIf="!IsRegistering"></app-header>

<div id="page" class="container-fluid">

  <div id="main">
    
      <router-outlet></router-outlet>

  </div>
</div>

<app-footer  *ngIf="!IsRegistering"></app-footer>