<div class="container-fluid">
  <app-banner-content [theme]="'dark'">
    <div class="text-center">
      <h1>Curved Vertical Headrails</h1>
      <p>
        Bend it Ltd specialise in shaping Louvolite's and Benthin vertical blind
        systems for bay (bow) and Arched windows
      </p>
    </div>
  </app-banner-content>

  <app-vogue-colours></app-vogue-colours>
  <app-feature-cards
    [title]="'Curved Headrails'"
    [message]="'Here is why we are different'"
    [features]="featuresTypes"
  ></app-feature-cards>

  <app-benthin-system></app-benthin-system>
</div>

<div class="container pb-3">
  <app-contact-form
    id="ContactForm"
    [productType]="'curves'"
  ></app-contact-form>
</div>
