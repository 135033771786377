import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  Control,
  Curve,
  Draw,
  LouvreWidth,
  SizesFrom,
  RailColour,
  Shape,
  System,
  YesNo,
} from "src/app/models/orders";
import { FormBuilder, Validators } from "@angular/forms";
import { Constants, KVP, KVPI } from "src/app/enums/constants";
import { OrderItem, Product } from "src/app/services/API.service";
import { StepperSelectionEvent } from "@angular/cdk/stepper/stepper";
import { createBhalfAValidator } from "../../../validators/createBhalfAValidator";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-headrail-edit",
  templateUrl: "./headrail-edit.component.html",
  styleUrls: ["./headrail-edit.component.scss"],
})
export class HeadrailEditComponent implements OnInit, OnChanges {
  curve: Curve;

  productIDs = [
    // Bow Bay,                          Splay Bay,                             Hockey L,                              Hockey R
    [
      "ea0a44e2-cfea-49a3-9348-ec5a61cdd9d3",
      "70eba64f-ee38-461c-b3b7-c868ee96bef5",
      "547280cb-c650-493d-8d81-a088ee41595e",
      "547280cb-c650-493d-8d81-a088ee41595e",
    ], // Vogue
    [
      "f10eec2d-b3a0-4f1e-a690-5d8586e6f454",
      "ec476cb9-78c5-4373-88d5-562d561427d6",
      "9ad467a7-9d9d-4c6e-b2f3-cf726975a530",
      "9ad467a7-9d9d-4c6e-b2f3-cf726975a530	",
    ], // Benbthin
  ];
  @Input() orderItem: OrderItem;
  @Input() orderID: string;
  @Input() viewOnly: boolean;
  @Input() product: Product;
  @Input() itemNumber: number;

  @Output() AddHeadrailClicked = new EventEmitter<OrderItem>();

  IsAddingNew = false;
  isVogue = true;
  isTrackoFrcedIn2Halves = false;
  isTrackIn2 = false;

  price = 0;
  discount = 0;
  fullPrice = 0;

  basePrice = 45;
  bendsPrice = 0;

  perMetre = 15.5;
  maxWidth = 5000;
  showDimC = false;
  showDimD = false;

  controlWithFocus = "";
  savedControlWithFocus = "";
  shape: KVP;
  savedShape = "";

  get selectedShape() {
    return this.designForm.get("shape").value;
  }
  selectedMeasurement: string;
  get selectedReveal() {
    return this.designForm.get("reveal").value;
  }
  get selectedSystem() {
    return this.designForm.get("system").value;
  }
  get selectedIn2halves() {
    return this.controlsForm.get("in2halves").value;
  }

  get suitableForWand(): boolean {
    return this.isVogue && this.selectedShape === this.shapes[Shape["Bow Bay"]].key && this.sizesForm.get("dimensionB").value < 351;
  }

  // DDLS
  shapes: KVPI[] = Constants.curveShapes;
  systems: KVP[] = this.convertEnumToKVP(System);
  sizesFrom: KVP[] = this.convertEnumToKVP(SizesFrom);
  louvreWidths: KVP[] = this.convertEnumToKVP(LouvreWidth);
  controls: KVP[] = this.convertEnumToKVP(Control);
  draws: KVP[] = this.convertEnumToKVP(Draw);
  railColours: KVP[] = this.convertEnumToKVP(RailColour);
  recesses: KVP[] = this.convertEnumToKVP(YesNo);
  reveals: KVP[] = this.convertEnumToKVP(YesNo);
  in2halves: KVP[] = this.convertEnumToKVP(YesNo);
  wands: KVP[] = this.convertEnumToKVP(YesNo);

  designForm = this.fb.group({
    id: undefined,
    shape: ["", Validators.required],
    system: [0, Validators.required],
    reveal: [0, Validators.required],
    // Order Item elements
    oid: undefined,
    quantity: 1,
    price: this.price,
    productID: "",
    orderID: "",
    offerID: "",
    name: "",
  });

  sizesForm = this.fb.group({
    dimensionA: ["", [Validators.required, Validators.min(500), Validators.max(12000)]],
    dimensionB: ["", [Validators.required, Validators.min(100)]],
    dimensionC: ["", [Validators.required, Validators.min(100)]],
    dimensionD: ["", [Validators.required, Validators.min(100)]],
    sizeFrom: ["", [Validators.required]],
    height: ["", [Validators.required, Validators.min(300)]],
  });

  controlsForm = this.fb.group({
    louvreWidth: ["", Validators.required],
    controls: ["", Validators.required],
    draw: ["", Validators.required],
    railColour: ["", Validators.required],
    in2halves: ["", Validators.required],
    wand: ["", Validators.required],
    location: ["", [Validators.required]],
  });

  constructor(private fb: FormBuilder, private authService: AuthService) { }

  ngOnChanges(changes: SimpleChanges): void {

  }

  convertEnumToKVP(myenum): KVP[] {
    let kvps: KVP[] = [];
    for (const kvpKey in myenum) {
      if (!isNaN(Number(kvpKey))) {
        kvps.push({ key: myenum[kvpKey], value: myenum[kvpKey] } as KVP);
      }
    }
    return kvps;
  }

  ngOnInit(): void {
    if (this.orderItem !== undefined) {
      this.curve = JSON.parse(this.orderItem.customerInputs) as Curve;
    }
    this.resetNewItemToOriginal();

    if (!!this.viewOnly) {
      this.designForm.disable();
      this.sizesForm.disable();
      this.controlsForm.disable();
    }

    if (this.product !== undefined) {
      // the selected product is available to pre-select the options for shap and system
      this.SetShapeAndtSystmeFromProductId(this.product.id);
    }
  }

  SetShapeAndtSystmeFromProductId(id: string) {

    this.productIDs.forEach((system, si) => {
      system.forEach((shape, i) => {
        if (shape === id) {
          this.designForm.patchValue({ shape: this.shapes[i].value });
          //      railColour: this.railColours[RailColour.White].value,
          this.shape = this.shapes[i];
          this.designForm.patchValue({ system: this.systems[si].value });
          this.designForm.updateValueAndValidity();
        }
      });
    })
  }

  ShapeFocus(shape: any) {
    this.ControlEnter(shape);
  }

  ControlEnter(
    newFocus: string,
    checkFormValid: boolean = false,
    isFormValid: boolean = false
  ) {
    if (checkFormValid && !isFormValid) {
      return;
    }

    // If a Dimension control set selectedMeasurment - used by the preview control
    if (newFocus.startsWith("dim") || newFocus.startsWith("hei")) {
      this.selectedMeasurement = newFocus;
    } else {
      this.selectedMeasurement = undefined;
    }

    this.savedControlWithFocus = this.controlWithFocus;
    this.controlWithFocus = newFocus;
  }

  ControlReset() {
    this.controlWithFocus = this.savedControlWithFocus;
    if (this.selectedMeasurement !== undefined) {
      this.selectedMeasurement = this.savedControlWithFocus;
    }
  }

  SetSelectedShape(shape: KVPI) {
    this.shape = shape;
    this.designForm.patchValue({ shape: shape.value });
    this.designForm.updateValueAndValidity();
  }

  onChanges() {
    // this.editForm.controls['isTimeShifted'].valueChanges
    //   .subscribe(isTimeShifted => {
    //     if (isTimeShifted === true) {
    //       this.editForm.controls['shiftedDelay'].enable();
    //     }
    //     else {
    //       this.editForm.controls['shiftedDelay'].disable();
    //     }
    //   });
  }

  resetNewItemToOriginal() {
    // set the temp to the orginal
    this.formSetValue();
  }

  formSetValue() {
    if (this.orderItem !== undefined) {
      this.IsAddingNew = false;
      this.designForm.reset({
        id: this.curve.id,
        shape: this.curve.shape,
        system: this.curve.system,
        reveal: this.curve.reveal,

        // Order Item elements
        oid: this.orderItem.id,
        quantity: this.orderItem.quantity,
        productID: this.orderItem.productID,
        orderID: this.orderItem.orderID,
        offerID: this.orderItem.offerID,
        name: this.curve.location,
        price: this.orderItem.price,
      });
      //this.ShapeEnter(Constants.curveShapes[this.curve.shape]);
      this.sizesForm.reset({
        dimensionA: this.curve.dimensionA,
        dimensionB: this.curve.dimensionB,
        dimensionC: this.curve.dimensionC,
        dimensionD: this.curve.dimensionD,
        sizeFrom: this.curve.sizeFrom,
        height: this.curve.height,
      });

      this.controlsForm.reset({
        louvreWidth: this.curve.louvreWidth,
        controls: this.curve.controls,
        draw: this.curve.draw,
        railColour: this.curve.railColour,
        in2halves: this.curve.in2halves,
        wand: this.curve.wand,
        location: this.curve.location
      });
    } else {
      this.IsAddingNew = true;
      // set defaults
      this.designForm.reset({
        id: undefined,
        shape: "",
        system: localStorage.getItem('defaultSystem') ?? this.systems[System.Vogue].value,
        reveal: this.reveals[YesNo.Yes].value,

        // Order Item elements
        oid: undefined,
        quantity: 1,
        price: this.price,
        productID: "",
        orderID: this.orderID,
        offerID: "test",
        name: "",
      });

      this.sizesForm.reset({
        dimensionA: undefined,
        dimensionB: undefined,
        dimensionC: undefined,
        dimensionD: undefined,
        sizeFrom: undefined,
        height: undefined,
      });

      this.controlsForm.reset({
        louvreWidth: this.louvreWidths[LouvreWidth["89mm"]].value,
        controls: this.controls[Control.Left].value,
        draw: this.isTrackoFrcedIn2Halves
          ? this.draws[Draw.Left].value
          : this.draws[Draw.Split].value,
        railColour: this.railColours[RailColour.White].value,
        in2halves: this.in2halves[YesNo.No].value,
        wand: this.wands[YesNo.No].value,
        location: "",
      });
    }
  }

  GetProductID(curve: Curve) {
    const ind = System[curve.system];
    const shape = Shape[curve.shape];
    return this.productIDs[ind][shape];
  }

  //  Validators.pattern(Constants.numberPattern)

  SubmitForm() {
    if (
      this.designForm.valid &&
      this.sizesForm.valid &&
      this.controlsForm.valid
    ) {
      const orderItem = Object.assign(
        {},
        this.designForm.value,
        this.sizesForm.value,
        this.controlsForm.value
      );
      this.curve = Object.assign({}, orderItem);
      this.curve.itemNumber = this.itemNumber;
      this.CalculatePrice();
      if (this.orderItem === undefined) {
        this.orderItem = {} as OrderItem;
      }
      localStorage.setItem('defaultSystem', this.curve.system.toString());
      this.orderItem.name = this.curve.location;
      this.orderItem.id = orderItem.oid;
      this.orderItem.customerInputs = JSON.stringify(this.curve);
      this.orderItem.quantity = orderItem.quantity;
      this.orderItem.orderID = orderItem.orderID;
      this.orderItem.productID = this.GetProductID(this.curve);
      this.orderItem.offerID = orderItem.offerID;
      this.orderItem.price = this.price;
      this.orderItem.discount = this.discount;
      this.orderItem.fullPrice = this.fullPrice;
      this.AddHeadrailClicked.emit(this.orderItem);
    }
  }

  CalculatePrice() {
    const widthInMetres =
      (this.curve.dimensionA + this.curve.dimensionB + (this.curve.shape === this.shapes[Shape["Splay Bay"]].key ? this.curve.dimensionC : 0)) / 1000;
    this.price =
      this.basePrice +
      this.bendsPrice +
      (widthInMetres < 1.5 ? 1.5 : widthInMetres) * this.perMetre;
    this.price += this.curve.in2halves.toString() === "Yes" ? this.isVogue ? 10 : 20 : 0;
    this.price += this.curve.motorised ? 450 : 0;
    this.price += this.curve.railColour.toString() !== "White" ? 15 : 0;

    const discountRate = this.authService.customer.standardItemsDiscount === null ? 0 : this.authService.customer.standardItemsDiscount;
    this.discount = Math.round(this.price * discountRate) / 100;
    this.fullPrice = Math.round(this.price * 100) / 100;
    this.price -= this.discount;
    this.price = Math.round(this.price * 100) / 100;
  }

  SelectionChanged(step: StepperSelectionEvent) {
    // console.log("Selection Changed");
    if (step.selectedIndex === 1 && step.previouslySelectedIndex === 0) {
      this.DesignDone();
    } else if (step.selectedIndex === 2 && step.previouslySelectedIndex === 1) {
      this.SizesDone();
    }
  }

  ToggleIn2Halves(state: any) {
    // const orderItem = Object.assign({}, this.designForm.value) as Curve;
    this.isTrackIn2 = state.value === this.in2halves[YesNo.Yes].key;

    if (this.isTrackIn2) {
      this.SetLLRR();
    } else {
      this.ResetLLRR();
    }
  }

  DesignDone() {
    const orderItem = Object.assign({}, this.designForm.value) as Curve;

    this.isVogue = orderItem.system === this.systems[System.Vogue].key;

    this.basePrice = this.isVogue ? 55 : 0;
    this.perMetre = this.isVogue ? 16.5 : 115;

    this.bendsPrice = 0;

    // validators for dimensionC/D
    if (orderItem.shape === this.shapes[Shape["Bow Bay"]].key) {
      // SPlay Bay needs A,B - remove C and D
      this.sizesForm['controls'].dimensionC.clearValidators();
      this.sizesForm['controls'].dimensionD.clearValidators();
    } else if (orderItem.shape !== this.shapes[Shape["Splay Bay"]].key) {
      // Hockey Sticks need A,B,C - remove D
      this.sizesForm['controls'].dimensionD.clearValidators();
    }

    if (orderItem.shape === this.shapes[Shape["Bow Bay"]].key) {
      // add custom validator - restriction is for Bow Bay only
      this.sizesForm.setValidators(createBhalfAValidator());
    } else {
      // remove custom validator - needed in case it was previously added
      this.sizesForm.clearValidators();
    }

    this.sizesForm.updateValueAndValidity();

    if (orderItem.shape === this.shapes[Shape["Splay Bay"]].key) {
      this.bendsPrice = this.isVogue ? 70 : 40;
    }

    if (
      orderItem.shape === this.shapes[Shape["Hockey Stick Left"]].key ||
      orderItem.shape === this.shapes[Shape["Hockey Stick Right"]].key
    ) {
      this.bendsPrice = this.isVogue ? 40 : 20;
    }

    this.showDimC = orderItem.shape !== this.shapes[Shape["Bow Bay"]].key;
    this.showDimD = orderItem.shape === this.shapes[Shape["Splay Bay"]].key;

    this.sizesForm.patchValue({
      dimensionC: !this.showDimC
        ? 0
        : this.IsAddingNew
          ? undefined
          : this.curve.dimensionC,
      dimensionD: !this.showDimD
        ? 0
        : this.IsAddingNew
          ? undefined
          : this.curve.dimensionD,
    });
  }

  SizesDone() {
    const orderItem = Object.assign(
      {},
      this.designForm.value,
      this.sizesForm.value
    ) as Curve;

    const width = orderItem.dimensionA + orderItem.dimensionB;

    this.maxWidth = this.isVogue ? 5000 : 6000;

    this.SetControls(width);
  }

  CheckSizes(): boolean {
    const orderItem = Object.assign(
      {},
      this.designForm.value,
      this.sizesForm.value
    ) as Curve;

    const success = orderItem.dimensionB <= orderItem.dimensionA / 2;

    return success;
  }

  private SetControls(width: number) {
    if (width > this.maxWidth) {
      this.isTrackoFrcedIn2Halves = true;
      this.SetLLRR();
    } else {
      this.isTrackoFrcedIn2Halves = false;
      this.ResetLLRR();
    }

    if (!this.isVogue) {
      this.controlsForm.patchValue({
        railColour: this.railColours[RailColour.White].value,
      });
    }
    if (!this.suitableForWand) {
      this.controlsForm.patchValue({
        wand: this.wands[YesNo.No].value
      });
    }
  }

  private SetLLRR() {
    this.controls.push({ key: "L R", value: "L R" });
    this.draws.push({ key: "L R", value: "L R" });
    this.controlsForm.patchValue({
      in2halves: this.in2halves[YesNo.Yes].value,
      controls: "L R",
      draw: "L R",
    });
  }

  private ResetLLRR() {
    this.controls = this.convertEnumToKVP(Control);
    this.draws = this.convertEnumToKVP(Draw);
    if (this.IsAddingNew) {
      this.controlsForm.patchValue({ controls: "Left" });
    }
  }
}

export interface IImg {
  Img: string;
  Show: boolean;
  Display();
}

export class DimA implements IImg {
  Img: string;
  Show: boolean;
  Display() {
    throw new Error("Method not implemented.");
  }
}

