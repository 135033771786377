import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IContact } from '../models/IContact';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionService {

  subscribe = 'https://w339d47kd9.execute-api.eu-west-2.amazonaws.com/VaccineScienceSubscription';

  constructor(private http: HttpClient) { }

  Subscribe(email: IContact) {
    const data = JSON.stringify(email);
    return this.http.post<string>(this.subscribe, data );
  }
}
