import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { APIService, Customer, Order, OrderStatus } from 'src/app/services/API.service';
import { AuthService, AuthState } from 'src/app/services/auth.service';

@Component({
  selector: 'app-account-home',
  templateUrl: './account-home.component.html',
  styleUrls: ['./account-home.component.scss']
})
export class AccountHomeComponent implements OnInit {

  userState: AuthState;
  authSub: Subscription;
  user;
  orders: Order[] = [];
  quotes: Order[] = [];
  delivered: Order[] = [];
  current: Order[] = [];
  customer: Customer;
  
  constructor(private authService: AuthService, private api: APIService) { }

  ngOnInit(): void {
    this.authSub = this.authService.auth$.subscribe((userState) => {
      this.userState = userState;
      this.GetOrders();
    });
    this.authService.auth$.subscribe(({ username }) => {
      this.user = username;
      this.customer = this.authService.customer;
    });
  }

  GetOrders() {
    this.api.OrdersByCustomer(this.userState.customerID).then((data) => {
      this.orders = data.items as Order[];
      this.quotes = data.items.filter(q => q.orderStatus === OrderStatus.quote) as Order[];
      this.delivered = data.items.filter(q => q.orderStatus === OrderStatus.delivered) as Order[];
      this.current = data.items.filter(q => q.orderStatus === OrderStatus.progress || q.orderStatus === OrderStatus.ordered || q.orderStatus === OrderStatus.ready) as Order[];
    });
  }
}
