import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Document } from 'src/app/services/API.service';
import awsconfig from 'src/aws-exports';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      state('*', style({
        opacity: 1
      })),
      transition('void <=> *', animate(500)),
    ]),
    trigger('fadeImage', [
      state('next', style({ opacity: 1 })),
      state('previous', style({ opacity: 1 })),
      state('reset', style({ opacity: 0 })),
      transition('* => next', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ]),
      transition('* => previous', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ImageViewerComponent implements OnInit {

  @Input() images: Document[] = [];
  @Output() selectDocument = new EventEmitter<Document>();

  s3bucket = 'https://' + awsconfig.aws_user_files_s3_bucket + '.s3.eu-west-2.amazonaws.com/public/';
  currentIndex = 0;
  showControls = false;
  multipleImages = false;
  imageState = 'next';

  constructor() { }

  ngOnInit(): void {
    if (this.images.length > 1) {
      this.multipleImages = true;
    }
  }

  next() {
    const last = this.currentIndex === this.images.length - 1;
   // last ? this.currentIndex = 0: this.currentIndex++;
    
    this.animateImage(last ? 0: this.currentIndex + 1);
  }

  previous() {
    const first = this.currentIndex === 0;
   // first ? this.currentIndex = this.images.length - 1: this.currentIndex--;
    
    this.animateImage(first ? this.images.length - 1 : this.currentIndex - 1);
  }

  setIndex(index: number) {
    this.currentIndex = index;
    this.animateImage(index);
  }

  animateImage(index: number) {
    this.imageState = 'reset';
  setTimeout(() => {
    this.imageState = 'next';
    this.currentIndex = index;
    // your existing code for next()
  }, 500);
  }

  SelectDocument(documentIndex: number) {
    this.selectDocument.emit(this.images[documentIndex]);
  }
}
