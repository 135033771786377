<div class="container-fluid text-center text-white p-4">
  <div class="container">
    <div class="row">
      <div class="">
        <p class="p-3 lead">
          If you need to order any Somfy products not covered by our ordering system please download the relevant order form below
        </p>
        <div class="row">
          <button
            *ngFor="let file of files | slice : 0 : 3"
            class="btn btn-primary m-3 col"
            (click)="downloadFile(file)"
          >
            {{ file.displayName }}
          </button>
        </div>
        <div class="row">
          <button
            *ngFor="let file of files | slice : 3 : 6"
            class="btn btn-primary m-3 col"
            (click)="downloadFile(file)"
          >
            {{ file.displayName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
