import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curtain-angular-v2',
  templateUrl: './curtain-angular-v2.component.html',
  styleUrls: ['./curtain-angular-v2.component.scss']
})
export class CurtainAngularV2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
