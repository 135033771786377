<div class="navbar-dark c-blue-dark-lt-bk p-5">
  <div class="container wow fadeInUp">

    <div class="text-center text-white">
      <h1>{{title}}</h1>
      <p class="text-white">{{message}}</p>
    </div>
    <div class="row pt-3">

        <div *ngFor="let feature of features" [class]="GetCols()" class="text-center  card-group p-3">
          <app-feature-card class="w-100" *ngIf="feature.iconCss !== null" [iconCss]="feature.iconCss" [title]="feature.title" [bodytext]="feature.bodytext" [delay]="feature.id"></app-feature-card>
          <app-feature-card-image *ngIf="feature.imgSrc !== null" [imgsrc]="feature.imgSrc" [title]="feature.title" [bodytext]="feature.bodytext" [delay]="feature.id"></app-feature-card-image>
        </div>

    </div>
  </div>
</div>
