<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 571 188.16" height="100%">
  <defs>
    <style>
      .cls-1,
      .cls-5,
      .cls-6 {
        fill: none;
      }

      .cls-1 {
        stroke: #fff;
        stroke-width: 10px;
      }

      .cls-1,
      .cls-4,
      .cls-5,
      .cls-6 {
        stroke-miterlimit: 10;
      }

      .cls-2 {
        fill: #8b2d0f;
      }

      .cls-3 {
        font-size: 12px;
        font-family: MyriadPro-Regular, Myriad Pro;
      }

      .cls-4 {
        fill: #fdff00;
        stroke: #2e3192;
      }

      .cls-5 {
        stroke-width: 2px;
        stroke-dasharray: 8 2;
      }

      .cls-6 {
        stroke: #ff371f;
      }
    </style>
  </defs>

  <path id="curveReveal" class="cls-1 revealYes" d="M501,150.94,418.6,72a47.42,47.42,0,0,0-35.87-16.63H54" />
  <path id="curveNoReveal" class="cls-1 revealNo" d="M483,133.94l-65.94-64c-7.24-8-21.16-15-32-15L54,54.47" />

  <g id="Reveal2">
    <rect class="cls-2 revealYes" x="0.5" y="28.44" width="53" height="53" />
    <path class="revealYes" d="M53,28.94v52H1v-52H53m1-1H0v54H54v-54Z" />
  </g>
  <g id="Reveal1">
    <rect class="cls-2 revealYes" x="517.5" y="134.66" width="53" height="53" />
    <path class="revealYes" d="M570,135.16v52H518v-52h52m1-1H517v54h54v-54Z" />
  </g>
  <g id="noReveal2">
    <rect class="cls-2 revealNo" x="0.5" y="20.44" width="53" height="10" />
    <path class="revealNo" d="M53,20.94v9H1v-9H53m1-1H0v11H54v-11Z" />
  </g>
  <g id="noReveal1">
    <rect class="cls-2 revealNo" x="517.5" y="125.44" width="53" height="10" />
    <path class="revealNo" d="M570,125.94v9H518v-9h52m1-1H517v11h54v-11Z" />
  </g>

  <polygon class="cls-4" points="517.21 135.21 522.91 129.61 417.74 22.43 54.06 22.43 54.06 30.43 414.38 30.43 517.21 135.21" />

    <g id="messureA">
      <line class="cls-5 dimAs" x1="410.6" y1="30.94" x2="57.4" y2="30.94" />
      <path class="dimA" d="M396.93,21.34a1,1,0,0,0,.3,1.38l12.91,8.22-12.91,8.22a1,1,0,0,0,1.08,1.69l14.23-9.06a1,1,0,0,0,.46-.85,1,1,0,0,0-.46-.84L398.31,21a1,1,0,0,0-.54-.16A1,1,0,0,0,396.93,21.34Z" />
      <path class="dimA" d="M71.07,21.34a1,1,0,0,1-.3,1.38L57.86,30.94l12.91,8.22a1,1,0,0,1-1.08,1.69L55.46,31.79a1,1,0,0,1-.46-.85,1,1,0,0,1,.46-.84L69.69,21a1,1,0,0,1,.54-.16A1,1,0,0,1,71.07,21.34Z" />
    </g>
    <text class="cls-3 dimA" transform="translate(220 47)">A - Front Width</text>

  <g id="measureB">
    <line class="cls-5 dimBs" x1="414.7" y1="32.64" x2="515.3" y2="133.24" />
    <path class="dimB" d="M417.58,49.1a1,1,0,0,0,.76-1.2L415,33,430,36.28a1,1,0,1,0,.43-2l-16.47-3.66a1,1,0,0,0-.92.27,1,1,0,0,0-.27.93l3.65,16.47a1.07,1.07,0,0,0,.27.49A1,1,0,0,0,417.58,49.1Z" />
    <path class="dimB" d="M498.85,130.36a1,1,0,0,1,1.19-.75L515,132.92,511.66,118a1,1,0,0,1,.76-1.19,1,1,0,0,1,1.2.76L517.27,134a1,1,0,0,1-1.19,1.19l-16.47-3.65a1,1,0,0,1-.49-.27A1,1,0,0,1,498.85,130.36Z" />
  </g>
  <text class="cls-3 dimB" transform="translate(425 65) rotate(45)">B - Return</text>

  <g id="measureC">
    <line class="cls-5 dimCs" x1="514.65" y1="134.42" x2="56.35" y2="31.47" />
    <path class="dimC" d="M503.42,122.05a1,1,0,0,0,0,1.42l10.79,10.84-14.39,5.2a1,1,0,0,0-.61,1.28,1,1,0,0,0,1.28.6l15.87-5.73a1,1,0,0,0,.64-.72,1,1,0,0,0-.27-.92l-11.89-12a1,1,0,0,0-.49-.27A1,1,0,0,0,503.42,122.05Z" />
    <path class="dimC" d="M71.79,25.1a1,1,0,0,1-.61,1.28L56.79,31.57,67.58,42.42a1,1,0,0,1,0,1.41,1,1,0,0,1-1.42,0l-11.89-12a1,1,0,0,1-.27-.93,1,1,0,0,1,.64-.72L70.51,24.5a.94.94,0,0,1,.55,0A1,1,0,0,1,71.79,25.1Z" />
  </g>
  <text class="cls-3 dimC" transform="translate(260 70) rotate(12.66)">C - Width (Reveal)</text>

  <line id="GuideACNoReveal" class="cls-6" x1="539.26" y1="135.84" x2="463.5" y2="135.44" />
  <line id="guideAB" class="cls-6" x1="425.3" y1="6.89" x2="404.7" y2="48.99" />
  <line id="guideBC" class="cls-6" x1="53.56" x2="53.56" y2="46.86" />
  <line id="guideACReveal" class="cls-6" x1="532.5" y1="118.44" x2="500.5" y2="152.44" />
</svg>
