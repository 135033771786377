import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';


@Component({
  selector: 'app-item-picker-image',
  templateUrl: './item-picker-image.component.html',
  styleUrls: ['./item-picker-image.component.scss']
})
export class ItemPickerImageComponent implements OnInit  {

  constructor(private fb: FormBuilder) { 
  }

  @Input() selections: any[];
  @Input() selected: any;
  @Input() showLabel = true;

  @Output() gotFocus = new EventEmitter();
  @Output() gotMouseEnter = new EventEmitter<string>();
  @Output() gotMouseLeave = new EventEmitter();
  @Output() selectedChanged = new EventEmitter<any>();

  disabled = false;

  onSelectionChanged(selected: any) {
    
    if (!this.disabled) {
        this.selected = selected;
        this.selectedChanged.emit(selected);
    }
  }

  ngOnInit(): void {
  }

  GetSelectedStatus(selection: any) {
    return selection === this.selected ? 'selected' : 'unselected';
  }

  SetMouseEnter(shape) {
    this.gotMouseEnter.emit(shape);
  }

  SetMouseLeave() {
    this.gotMouseLeave.emit();
  }

  SetFocus(shape) {
    this.gotFocus.emit(shape);
  }
}
